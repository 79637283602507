exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-american-school-of-pennsylvania-js": () => import("./../../../src/pages/american-school-of-pennsylvania.js" /* webpackChunkName: "component---src-pages-american-school-of-pennsylvania-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-programs-american-curriculum-development-js": () => import("./../../../src/pages/programs/american-curriculum-development.js" /* webpackChunkName: "component---src-pages-programs-american-curriculum-development-js" */),
  "component---src-pages-programs-early-childhood-education-js": () => import("./../../../src/pages/programs/early-childhood-education.js" /* webpackChunkName: "component---src-pages-programs-early-childhood-education-js" */),
  "component---src-pages-programs-higher-education-programs-js": () => import("./../../../src/pages/programs/higher-education-programs.js" /* webpackChunkName: "component---src-pages-programs-higher-education-programs-js" */),
  "component---src-pages-programs-restaurants-and-food-services-js": () => import("./../../../src/pages/programs/restaurants-and-food-services.js" /* webpackChunkName: "component---src-pages-programs-restaurants-and-food-services-js" */),
  "component---src-pages-programs-study-in-america-js": () => import("./../../../src/pages/programs/study-in-america.js" /* webpackChunkName: "component---src-pages-programs-study-in-america-js" */),
  "component---src-pages-programs-us-highschool-js": () => import("./../../../src/pages/programs/us-highschool.js" /* webpackChunkName: "component---src-pages-programs-us-highschool-js" */),
  "component---src-pages-programs-us-university-admissions-consulting-services-js": () => import("./../../../src/pages/programs/us-university-admissions-consulting-services.js" /* webpackChunkName: "component---src-pages-programs-us-university-admissions-consulting-services-js" */),
  "component---src-pages-programs-us-work-immigration-consulting-js": () => import("./../../../src/pages/programs/us-work-immigration-consulting.js" /* webpackChunkName: "component---src-pages-programs-us-work-immigration-consulting-js" */)
}

